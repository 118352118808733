<template>
  <v-col cols="12" sm="9">
    <ProgressCircular :isLoaded="isDataRefreshed" />
    <v-skeleton-loader v-if="showLoading" type="table-row@3"></v-skeleton-loader>
    <div v-else class="overflow-x-auto">
      <p class="font-weight-medium text-lg-h6 text--secondary">Меню керування пристроями</p>
      <table>
        <tr>
          <th v-for="(title, index) in tableHeader" :key="index" :colspan="title === 'Версія ПЗ' ? 2 : 1">{{ title }}</th>
        </tr>
        <tr class="terminal-row">
          <td>
            <router-link
              :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId } }"
              class="font-weight-medium text-decoration-none ml-2 d-inline-block"
            >
              T {{ terminalId }}
            </router-link>
          </td>
          <td></td>
          <td>{{ currentTerminal.model }}</td>
          <td>{{ currentTerminal.state ? currentTerminal.state.info.pcb : 'No info' }}</td>
          <td>{{ currentTerminal.state ? currentTerminal.state.info.bootloader : 'No info' }}</td>
          <td>{{ currentTerminal.state ? currentTerminal.state.info.firmware : 'No info' }}</td>
          <td class="td-download">
            <v-tooltip top>
              <template v-slot:activator="{ on, attr }">
                <v-btn x-small class="grey--text" v-bind="attr" v-on="on" @click="firmwareDialogClick">
                  <v-icon small class="">mdi-download</v-icon>
                </v-btn>
              </template>
              Оновлення ПЗ
            </v-tooltip>
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isServicePointExist"
                  @click="showRemoveTerminalModal = true"
                  color="blue-grey lighten-3"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  x-small
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </template>
              <span>Видалити термінал з точки</span>
            </v-tooltip>
          </td>
          <td style="min-width: 250px">
            <div class="select-wrapper d-flex align-center">
              <PagingSelect
                :options="terminalEvents"
                :options-meta="terminalEventsMeta"
                :options-params="terminalEventsOptions"
                item-value="event_code"
                class="mr-3"
                outlined
                dense
                hide-details
                clearable
                @change="(value) => (selectedTerminalEvent = value)"
                ref="terminalCommands"
              >
                <template v-slot:prepend-item>
                  <v-btn @click="openPackageDialog" class="ml-4 my-2" small outlined>Відправити пакет</v-btn>
                </template>
                <template v-slot:label><span class="font12">Оберіть команду</span></template>
                <template v-slot:selection="{ item }">
                  <span class="font12">{{ getItemLocaleData(item.name) }} ({{ item.event_code }})</span>
                </template>
                <template v-slot:item="{ item }">
                  <span class="font12">{{ getItemLocaleData(item.name) }} ({{ item.event_code }})</span>
                </template>
              </PagingSelect>
              <v-btn
                @click="sendTerminalCommand"
                color="blue-grey lighten-3"
                :disabled="!selectedTerminalEvent"
                :dark="!!selectedTerminalEvent"
                depressed
                small
              >
                <v-icon>mdi-share</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
        <tr v-for="(device, index) in currentTerminal.devices" :key="device.id">
          <td>
            <router-link
              :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: device.id } }"
              class="text-decoration-none"
            >
              {{ getDeviceTitle(device.type) }} {{ device.id }}
            </router-link>
          </td>
          <td>{{ device.connection_number }}</td>
          <td>{{ device.model }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openRemoveDeviceDialog(device)"
                  color="blue-grey lighten-3"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  outlined
                  x-small
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </template>
              <span>Видалити автомат з терміналу</span>
            </v-tooltip>
          </td>
          <td>
            <div class="select-wrapper d-flex align-center">
              <PagingSelect
                :options="deviceEvents"
                :options-meta="deviceEventsMeta"
                :options-params="deviceEventsOptions"
                item-value="event_code"
                class="mr-3"
                outlined
                dense
                hide-details
                clearable
                @change="setDeviceEvent"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ getItemLocaleData(item.name) }} ({{ item.event_code }})</span>
                </template>
                <template v-slot:label>
                  <span>Оберіть команду</span>
                </template>
                <template v-slot:item="{ item }">
                  <span>{{ getItemLocaleData(item.name) }} ({{ item.event_code }})</span>
                </template>
              </PagingSelect>
              <v-btn
                @click="sendDeviceCommand(index)"
                color="blue-grey lighten-3"
                :disabled="!selectedDeviceEvent"
                :dark="!!selectedDeviceEvent"
                depressed
                small
              >
                <v-icon>mdi-share</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
        <tr>
          <td style="position: relative">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openDeviceConnectDialog"
                  color="blue-grey lighten-3"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  block
                  depressed
                  height="100%"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Додати автомат</span>
            </v-tooltip>
            <div class="info-icon">
              <v-tooltip top>
                <template v-slot:activator="{ on, attr }">
                  <v-icon v-bind="attr" v-on="on" size="18" color="primary">mdi-information-outline</v-icon>
                </template>
                Після підключення нового девайса інформація в моніторингу оновиться через кілька хвилин
              </v-tooltip>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-4">
      <p class="font-weight-medium text-lg-h6 text--secondary mb-0">Додаткове обладнання</p>
      <div>
        <v-checkbox
          v-model="storeShowBillAcceptor"
          @change="handleShowBillAcceptorChange"
          class="mt-0 d-inline-block"
          label="Купюроприймач"
          hide-details
        ></v-checkbox>
      </div>
      <div>
        <v-checkbox
          v-model="storeShowPosTerminal"
          @change="handleShowPosTerminalChange"
          class="mt-0 d-inline-block"
          label="POS-термінал"
          hide-details
        ></v-checkbox>
      </div>
    </div>

    <v-dialog v-model="openDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Підключення автомата до терміналу</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-row>
                <PagingSelect
                  v-model="selectedDevice"
                  :options="devices"
                  :options-meta="devicesMeta"
                  :options-params="devicesOptions"
                  item-value="id"
                  label="Оберіть, будь ласка, автомат, який треба підключити"
                  @change="setConnectionNumber"
                  @input="$v.selectedDevice.$touch()"
                  @blur="$v.selectedDevice.$touch()"
                  :error-messages="selectedDeviceErrors"
                >
                  <template v-slot:selection="{ item }">
                    {{ getMachineDesc(item.type) }} ({{ getDeviceTitle(item.type) }}{{ item.id }})
                  </template>
                  <template v-slot:item="{ item }">
                    {{ getMachineDesc(item.type) }} ({{ getDeviceTitle(item.type) }}{{ item.id }})
                  </template>
                </PagingSelect>
              </v-row>
              <v-row class="my-3" v-if="currentTerminal.devices && currentTerminal.devices.length">
                <span>Зайняті номери підключень: {{ connectionNumbersString }}</span>
              </v-row>
              <v-row>
                <v-text-field
                  v-model.number="deviceNumber"
                  label="Номер пристрою на терміналі"
                  @input="$v.deviceNumber.$touch()"
                  @blur="$v.deviceNumber.$touch()"
                  :error-messages="deviceNumberErrors"
                ></v-text-field>
              </v-row>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addDeviceToTerminal" color="primary" text>Так</v-btn>
          <v-btn text @click="openDialog = false">Нi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="packageDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 justify-center">Відправити пакет налаштувань</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="packageJSON"
            @input="$v.packageJSON.$touch()"
            @blur="$v.packageJSON.$touch()"
            :error-messages="packageJSONErrors"
            outlined
            label="JSON Data"
            clearable
            filled
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="sendTerminalPackage" color="primary" text>Так</v-btn>
          <v-btn text @click="closePackageDialog">Нi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openFirmwareDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">Меню оновлення ПЗ</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="currentTerminal.firmware">
              <v-col cols="4">
                <span class="font-weight-bold">Поточна версія ПЗ:</span>
              </v-col>
              <v-col cols="4">
                <span>{{ currentTerminal.firmware.version_name }} (#{{ currentTerminal.firmware.id }})</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <span class="font-weight-bold">Нова версія ПЗ:</span>
              </v-col>
              <v-col cols="4">
                <span v-if="currentTerminal.firmware && currentTerminal.firmware.last_firmware">
                  {{ currentTerminal.firmware.last_firmware.version_name }} (#{{ currentTerminal.firmware.last_firmware.id }})
                </span>
                <span v-else>----------------</span>
              </v-col>
              <v-col cols="4">
                <v-btn color="success" :disabled="!canInstallNewFirmware || isLastFirmwareInstalling" @click="installFirmware">{{
                  isLastFirmwareInstalling ? 'Встановлення...' : 'Встановити'
                }}</v-btn>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="4">
                <span class="font-weight-bold">Попередні версії ПЗ:</span>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-if="firmwareOldVersions.length"
                  v-model="selectedFirmwareOldVersion"
                  :items="firmwareOldVersions"
                  label="Оберіть..."
                  item-value="id"
                  item-text="version_name"
                >
                  <template v-slot:item="{ item }"> {{ item.version_name }} (#{{ item.id }}) </template>
                </v-select>
                <span v-else>Немає</span>
              </v-col>
              <v-col cols="4">
                <v-btn :disabled="!selectedFirmwareOldVersion" @click="() => installFirmware(selectedFirmwareOldVersion)"
                  >Встановити</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openFirmwareDialog = false" color="primary" text>Закрити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmActionModal @closeModal="showRemoveTerminalModal = false" :showModal="showRemoveTerminalModal">
      <template v-slot:title>Від`єднати термінал?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="disconnectTerminalFromSP" color="primary" text>Так</v-btn>
        <v-btn @click="showRemoveTerminalModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>

    <ConfirmActionModal @closeModal="showRemoveDeviceModal = false" :showModal="showRemoveDeviceModal">
      <template v-slot:title>Від`єднати автомат?</template>
      <template v-slot:text>Дані будуть оновлені</template>
      <template v-slot:buttons>
        <v-btn @click="disconnectDeviceFromTerminal" color="primary" text>Так</v-btn>
        <v-btn @click="showRemoveDeviceModal = false" text>Нi</v-btn>
      </template>
    </ConfirmActionModal>
  </v-col>
</template>

<script>
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import { mapActions, mapMutations, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { integer, minValue, required } from 'vuelidate/lib/validators'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'
import { deviceEventTypeId, terminalEventTypeId } from '@/const/constructor/eventTypes'
import getLocaleData from '@/mixins/getLocaleData'

export default {
  name: 'DevicesTable',

  components: {
    PagingSelect: () => import('@/components/common/PagingSelect'),
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  props: {
    terminalId: {
      type: Number,
      required: true,
    },
  },

  mixins: [validationMixin, convertDevicesTypes, getLocaleData],
  emits: ['disconnected'],

  validations() {
    return {
      selectedDevice: { required },
      packageJSON: { required },
      deviceNumber: {
        required,
        integer,
        minValue: minValue(1),
        notInArr: (value) => !this.connectionNumbersArr.includes(value),
      },
    }
  },

  data() {
    return {
      currentTerminal: {},
      showRemoveTerminalModal: false,
      showRemoveDeviceModal: false,
      tableHeader: [
        'ID',
        'Номер',
        'Модель',
        'Версія плати',
        'Версія бутлоадера',
        'Версія ПЗ',
        'Відкріпити',
        'Відправити команду',
      ],
      devicesOptions: null,
      deviceEventsOptions: null,
      selectedTerminalEvent: null,
      selectedDeviceEvent: null,
      showLoading: true,
      isDataRefreshed: true,
      packageDialog: false,
      openDialog: false,
      openFirmwareDialog: false,
      selectedDevice: null,
      deviceNumber: null,
      packageJSON: '',
      connectionNumbersArr: [],
      connectionNumbersString: '',
      page: 1,
      storeShowBillAcceptor: false,
      storeShowPosTerminal: false,
      selectedFirmwareOldVersion: null,
    }
  },

  async mounted() {
    await this.initialize()
  },

  computed: {
    ...mapState('terminals', ['terminal']),
    ...mapState('devices', ['devices', 'devicesMeta']),
    ...mapState('dictionaries', ['machinesTypesDictionary']),
    ...mapState('infoConstructor', ['terminalEvents', 'terminalEventsMeta', 'deviceEvents', 'deviceEventsMeta']),
    ...mapState('monitoring', ['showBillAcceptor', 'showPosTerminal']),
    ...mapState('firmwares', ['firmwareOldVersions', 'installingFirmwares']),
    ...mapState('logs', ['progressData']),

    selectedDeviceErrors() {
      const errors = []
      if (!this.$v.selectedDevice.$dirty) return errors
      !this.$v.selectedDevice.required && errors.push('Це поле обовʼязкове')
      return errors
    },
    packageJSONErrors() {
      const errors = []
      if (!this.$v.packageJSON.$dirty) return errors
      !this.$v.packageJSON.required && errors.push('Пакет налаштувань не може бути пустим обʼєктом')
      return errors
    },
    deviceNumberErrors() {
      const errors = []
      if (!this.$v.deviceNumber.$dirty) return errors
      !this.$v.deviceNumber.required && errors.push('Це поле обов"язкове')
      !this.$v.deviceNumber.integer && errors.push('Введіть, будь ласка, ціле число')
      !this.$v.deviceNumber.notInArr && errors.push('Цей номер вже зайнятий')
      return errors
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    isServicePointExist() {
      return !isObjectEmpty(this.servicePoint)
    },
    servicePoint() {
      return this.terminal?.service_point || {}
    },
    canInstallNewFirmware() {
      return (
        this.currentTerminal?.firmware &&
        this.currentTerminal.firmware.last_firmware?.is_available &&
        this.currentTerminal.firmware.version_name !== this.currentTerminal.firmware.last_firmware.version_name
      )
    },
    isLastFirmwareInstalling() {
      const index = this.installingFirmwares.findIndex(
        (firmware) =>
          firmware.terminals && firmware.terminals.findIndex((terminal) => terminal.id === this.currentTerminal.id) > -1
      )
      return index > -1
    },
  },

  watch: {
    showBillAcceptor: {
      handler(newValue) {
        this.storeShowBillAcceptor = newValue
      },
      immediate: true,
    },
    showPosTerminal: {
      handler(newValue) {
        this.storeShowPosTerminal = newValue
      },
      immediate: true,
    },
    packageDialog: {
      handler(newValue) {
        if (!newValue) this.$v.packageJSON.$reset()
      },
      immediate: false,
    },
  },

  methods: {
    ...mapActions('infoConstructor', ['loadAdditionalInfoEventCodes']),
    ...mapActions('monitoring', ['sendMonitoringTerminalCommand', 'sendMonitoringDeviceCommand']),
    ...mapActions('terminals', ['loadSelectedTerminal', 'connectDeviceToTerminal', 'removeDeviceFromTerminal', 'sendPackage']),
    ...mapActions('devices', ['loadDevices']),
    ...mapActions('servicePoints', ['removeTerminalFromServicePoint']),
    ...mapActions('firmwares', ['loadFirmwareOldVersions', 'upgradeFirmware']),
    ...mapActions('logs', ['addProgressData']),
    ...mapMutations('terminals', ['SET_SELECTED_TERMINAL']),
    ...mapMutations('monitoring', ['TOGGLE_BILL_ACCEPTOR', 'TOGGLE_POS_TERMINAL']),

    setConnectionNumber(value) {
      function getFreeNumber(arr) {
        if (arr.length === 0 || arr[0] !== 1) {
          return 1
        }
        for (let i = 1; i < arr.length; i++) {
          if (arr[i] - arr[i - 1] > 1) {
            return arr[i - 1] + 1
          }
        }
        return arr[arr.length - 1] + 1
      }
      this.selectedDevice = value
      this.connectionNumbersArr = this.currentTerminal?.devices?.map((item) => item.connection_number).sort((a, b) => a - b)
      this.connectionNumbersString = this.connectionNumbersArr.join(', ')
      this.deviceNumber = getFreeNumber(this.connectionNumbersArr)
      this.loading = false
    },
    setDeviceEvent(value) {
      this.selectedDeviceEvent = value
    },
    async initialize() {
      this.$v.$reset()
      this.terminalEventsOptions = {
        loadingFunction: this.loadAdditionalInfoEventCodes,
        payload: {
          eventType: terminalEventTypeId,
          forSearch: 1,
        },
      }
      this.deviceEventsOptions = {
        loadingFunction: this.loadAdditionalInfoEventCodes,
        payload: {
          eventType: deviceEventTypeId,
          forSearch: 1,
        },
      }
      this.devicesOptions = {
        loadingFunction: this.loadDevices,
        payload: {
          company: this.currentCompanyId,
          forSearch: 1,
          show_unconnected: 1,
        },
      }
      if (this.terminal.id !== this.terminalId) {
        await this.refreshData()
      } else {
        this.currentTerminal = {
          ...this.terminal,
          devices: [...this.terminal.devices?.sort((a, b) => a.connection_number - b.connection_number)] || [],
        }
      }
      this.setConnectionNumber()
      this.showLoading = false
    },
    async openDeviceConnectDialog() {
      await this.loadDevices(this.devicesOptions.payload)
      this.selectedDevice = null
      this.openDialog = true
      this.$v.$reset()
    },
    async refreshData() {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
      }
      await this.loadSelectedTerminal(payload)
      await this.loadDevices(this.devicesOptions.payload)
      this.currentTerminal = {
        ...this.terminal,
        devices: [...this.terminal.devices?.sort((a, b) => a.connection_number - b.connection_number)] || [],
      }
      this.setConnectionNumber()
    },
    async addDeviceToTerminal() {
      this.$v.selectedDevice.$touch()
      this.$v.deviceNumber.$touch()
      if (!this.$v.$anyError) {
        this.isDataRefreshed = false
        this.openDialog = false
        const params = {
          companyId: this.currentCompanyId,
          terminalId: this.terminalId,
          deviceId: this.selectedDevice.id,
          connectionNumber: { connection_number: this.deviceNumber },
        }
        try {
          await this.connectDeviceToTerminal(params)
        } finally {
          await this.refreshData()
          this.isDataRefreshed = true
        }
      }
    },
    async sendTerminalPackage() {
      this.$v.packageJSON.$touch()
      if (this.$v.$anyError) return
      this.packageDialog = false
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
        data: JSON.stringify(this.packageJSON).replace(/\\"/g, '"'),
      }
      await this.sendPackage(payload)
      this.packageJSON = ''
    },
    async disconnectDeviceFromTerminal() {
      this.isDataRefreshed = false
      this.showRemoveDeviceModal = false
      const params = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
        deviceId: this.selectedDevice.id,
      }
      await this.removeDeviceFromTerminal(params)
      await this.refreshData()
      this.isDataRefreshed = true
    },
    async disconnectTerminalFromSP() {
      this.isDataRefreshed = false
      this.showRemoveTerminalModal = false
      const terminalParams = {
        companyId: this.currentCompanyId,
        servicePointId: this.servicePoint.id,
        terminalId: this.terminalId,
      }
      await this.removeTerminalFromServicePoint(terminalParams)
      this.$emit('disconnected')
      this.isDataRefreshed = true
    },
    async sendTerminalCommand() {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
        directiveTypeId: this.selectedTerminalEvent.event_code,
      }
      await this.sendMonitoringTerminalCommand(payload)
    },
    async sendDeviceCommand(index) {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.terminalId,
        deviceId: this.terminal.devices[index].id,
        directiveTypeId: this.selectedDeviceEvent.event_code,
      }
      await this.sendMonitoringDeviceCommand(payload)
    },
    openPackageDialog() {
      this.packageDialog = true
      this.$refs.terminalCommands.blurSelect()
    },
    openRemoveDeviceDialog(selectedDevice) {
      this.showRemoveDeviceModal = true
      this.selectedDevice = selectedDevice
    },
    closePackageDialog() {
      this.packageDialog = false
      this.packageJSON = ''
    },
    handleShowBillAcceptorChange() {
      this.TOGGLE_BILL_ACCEPTOR(this.storeShowBillAcceptor)
      const billAcceptorStorageData = {
        terminalId: this.terminalId,
        isShow: this.storeShowBillAcceptor,
      }
      window.localStorage.setItem('billAcceptor', JSON.stringify(billAcceptorStorageData))
    },
    handleShowPosTerminalChange() {
      this.TOGGLE_POS_TERMINAL(this.storeShowPosTerminal)
      const posTerminalStorageData = {
        terminalId: this.terminalId,
        isShow: this.storeShowPosTerminal,
      }
      window.localStorage.setItem('posTerminal', JSON.stringify(posTerminalStorageData))
    },
    async firmwareDialogClick() {
      const payload = { companyId: this.currentCompanyId, terminalId: this.terminalId }
      await this.loadFirmwareOldVersions(payload)
      this.openFirmwareDialog = true
    },
    async installFirmware() {
      const payload = {
        companyId: this.currentCompanyId,
        firmware: this.currentTerminal.firmware.last_firmware,
        terminals: [this.currentTerminal],
      }
      await this.upgradeFirmware(payload)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/table';

.info-icon {
  position: absolute;
  right: -40px;
  bottom: 8px;
}

.select-wrapper {
  max-width: 450px;
}

.td-download {
  width: 30px;
  cursor: pointer;
}

table {
  overflow: auto;
}

.terminal-row {
  background-color: lighten(#cfd8dc, 12%);
}
</style>
